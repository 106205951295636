<template>
  <div
    v-if="!loading && hasCompetitions"
    :class="[
      'competition-filter',
      { 'competition-filter--desktop': !isMobile },
    ]"
  >
    <div class="competition-filter__body">
      <div
        v-for="competition in competitions"
        :key="competition.competitionId"
        :class="[
          'competition-filter__item',
          { 'competition-filter__item--selected': competition.competitionId === competitionId },
        ]"
        :ref="ref => itemRefs[competition.competitionId] = ref"
        @click="selectCompetition(competition)"
      >
        {{ competition.competitionName }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useBreakpoints } from '@/composables';

export default {
  setup() {
    const store = useStore();

    /*
    const hasEvents = computed(() => store.getters.events?.length);
    const competitions = computed(() => {
      if (!hasEvents.value) return [];
      return [
        {
          competitionId: '',
          competitionName: 'All events',
        },
        ...store.getters.competitions,
      ];
    });
    */

    const { isMobile } = useBreakpoints();

    const competitions = computed(() => {
      if (!store.getters.competitions.length) return [];
      return [
        {
          competitionId: '',
          competitionName: 'All events',
        },
        ...store.getters.competitions,
      ];
    });

    const loading = computed(() => store.getters.competitionsLoading);
    const hasCompetitions = computed(() => !!competitions.value?.length);
    const competitionId = computed(() => store.getters.selectedCompetitionId);

    const itemRefs = ref({});
    const selectionTop = computed(() => {
      const itemRef = itemRefs.value[competitionId.value];
      if (!itemRef) return 0;
      return itemRef.offsetTop;
    });

    const selectCompetition = ({ competitionId: id }) => {
      if (competitionId.value === id) return;
      store.dispatch('selectCompetition', id);
    };

    return {
      isMobile,
      competitions,
      loading,
      hasCompetitions,
      competitionId,
      itemRefs,
      selectionTop,
      selectCompetition,
    };
  },
};
</script>

<style lang="scss" scoped>
.competition-filter {
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  font-size: 0.875rem;
  width: 100%;
}

.competition-filter__icon {
  fill: inherit;
  stroke: none;
  height: 1rem;
  margin-right: 0.25rem;
  aspect-ratio: 1 / 1;

  &.competition-filter__icon--stroke {
    fill: none;
    stroke: inherit;
  }
}

.competition-filter__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  font-size: 0.625rem;
  color: #6D6D6D;
  fill: #6D6D6D;
  text-transform: uppercase;
  font-weight: 600;
}

.competition-filter__body {
  display: flex;
  overflow-x: scroll;
  padding: 0 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 0.75rem;

  &::-webkit-scrollbar {
    display: none;
  }
}

.competition-filter__item {
  display: flex;
  align-items: center;
  height: 2.75rem;
  padding: 0 1rem;
  border-radius: 0.25rem;
  user-select: none;
  fill: #000;
  stroke: #000;
  white-space: nowrap;
  background-color: #fff;
  border: 1px solid #F0F0F0;
  cursor: pointer;

  &.competition-filter__item--selected {
    background-color: #003C3C;
    color: #fff;
    fill: #fff;
    stroke: #fff;
  }
}

.competition-filter.competition-filter--desktop {
  height: 2.75rem;

  .competition-filter__body {
    padding: 0;
  }
}
</style>
